<template>
  <div class="AdvanceManagement">
    <div class="Advance-title">
      <p>预付货款</p>
    </div>
    <div class="Advance-search">
      <div>
        搜索：
        <a-input-search v-model="form.searchText" placeholder="请输入卖方名称" @search="form.pageNum = 1;preAcctList()" style="width: 300px" />
      </div>
      <a-button type="primary" @click="$refs.payModal.visible = true">
        <a-icon type="plus" /> 新增预付货款
      </a-button>
    </div>
    <div class="tabls">
      <a-table :rowKey="(r, i) => i" :columns="columns" :data-source="data" @change="handleTableChange($event, 'form')" :pagination="{
        size: 'big',
        total: total,
        current: form.pageNum,
        pageSize: form.pageSize,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '20', '30', '40'],
        showTotal: total => `共有 ${total} 条记录`}" :loading="loading">
        <template slot="handle" slot-scope="text, row">
          <a @click="view(row.id)">查看详情</a>
        </template>
      </a-table>
    </div>
    <AdvancePayModal ref="payModal" :purchaserId="$route.query.entityId" :customData="customData" @ok="pay" @cancel="customData = null"/>

    <a-modal :zIndex="99" :width="990" :visible="visibles" class="details" footer="" @cancel="detailsCancel">
      <a-spin :spinning="spinDetails">
        <div class="d-head">
          <a-row>
            <a-col :span="24" class="d-w d-title">预付货款账户流水详情</a-col>
            <a-col :span="12">
              卖方：<span class="d-w d-text">{{detailsObj.supplierName}}</span>
            </a-col>
            <a-col :span="12">
              买方：<span class="d-w d-text">{{detailsObj.commercialName}}</span>
            </a-col>
            <a-col :span="12">
              预付货款余额：<span class="d-w d-text-o">¥ {{detailsObj.availableBalance}}</span>
            </a-col>
            <a-col :span="12">
              未到账预付货款：<span class="d-w d-text-o">¥ {{detailsObj.unreceivedBalance}}</span>
            </a-col>
            <a-col :span="24" align="right">
              <a-button type="primary" @click="add">
                <a-icon type="plus" /> 新增预付货款
              </a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>

      <a-table :dataSource="dataDetails" :columns="columnsDetails" :rowKey="(r, i) => i" size="middle" class="d-table" @change="handleTableChange($event, 'formDetail')"
        :pagination="{
        size: 'big',
        total: totalDetails,
        current: formDetail.pageNum,
        pageSize: formDetail.pageSize,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '20', '30', '40'],
        showTotal: total => `共有 ${total} 条数据`}" :loading="loadingDetails">
        <span slot="payStatus" slot-scope="text">
          <a-badge :color="statusObj[text]" :text="text" />
        </span>
        <template slot="handle" slot-scope="text, record">
          <a-space v-if="record.payStatus!='已关闭'">
            <a-popconfirm v-if="record.payStatus=='未支付'" cancelText="取消" okText="确定" title="确定关闭?"
              @confirm="() => closePay(record.paymentId)">
              <a>关闭支付</a>
            </a-popconfirm>
            <span v-if="record.bankReceiptFile" @click="lookReceipt(record.bankReceiptFile)"><a>查看回单</a> </span>
            <a v-if="record.usedAmount != 0" @click="deductionFun(record.paymentNo)">抵扣详情</a>
          </a-space>
        </template>
      </a-table>
    </a-modal>

    <a-modal title="抵扣详情" v-if="visibleDeduction" :width="500" :visible="visibleDeduction" :footer="null" centered
      @cancel="visibleDeduction = false">
      <a-table :rowKey="(r, i) => i" :dataSource="dataDeduction" :loading="loadingDeduction" :columns="columnsDeduction"
        size="middle" class="d-table" :pagination="false"></a-table>
    </a-modal>

    <!-- 查看回单 -->
    <a-modal :width="800" :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img style="width: 100%" v-if="bigImg.indexOf('.pdf') === -1" :src="bigImg" />
      <iframe v-else :src="bigImg" style="width:100%;height:800px;margin-top:30px;border:1px solid #f2f2f2"
        frameborder="0"></iframe>
    </a-modal>
  </div>
</template>

<script>
import AdvancePayModal from "./components/AdvancePayModal.vue";
import { preAcctList, preAcctRecordList, preAcctRecord, useList, closePay } from '@/api/buyer'
const columns = [
  {
    title: "最后更新日期",
    dataIndex: "lastUpdatedTime"
  },
  {
    title: "卖家",
    dataIndex: "supplierName"
  },
  {
    title: "预付货款余额",
    dataIndex: "availableBalance"
  },
  {
    title: "操作",
    scopedSlots: { customRender: "handle" }
  },
];

const columnsDetails = [
  {
    title: "支付日期",
    dataIndex: "createdTime"
  },
  {
    title: "支付状态",
    scopedSlots: { customRender: "payStatus" },
    dataIndex: "payStatus"
  },
  {
    title: "预付货款（元）",
    align: 'right',
    dataIndex: "totalAmount"
  },
  {
    title: "已抵扣（元）",
    align: 'right',
    dataIndex: "usedAmount"
  },
  {
    title: "剩余可用（元）",
    align: 'right',
    dataIndex: "notUsedAmount"
  },
  {
    title: "支付编号",
    dataIndex: "paymentNo"
  },
  {
    title: "操作",
    scopedSlots: { customRender: "handle" }
  },
]

const columnsDeduction = [
  {
    title: "抵扣日期",
    dataIndex: "useTime"
  },
  {
    title: "抵扣订单",
    align: 'center',
    dataIndex: "orderId"
  },
  {
    title: "抵扣金额（元）",
    align: 'right',
    dataIndex: "useAmount"
  }
]

export default {
  components: {
    AdvancePayModal
  },
  data() {
    return {
      data: [],
      detailsObj: {},
      dataDetails: [],
      dataDeduction: [],
      columns,
      columnsDetails,
      columnsDeduction,
      value: 1,
      radioStyle: {
        background: "rgba(255, 204, 102, 1)",
      },
      loading: false,
      spinDetails: false,
      loadingDetails: false,
      loadingDeduction: false,
      visibles: false,
      visibleDeduction: false,
      total: 0,
      form: {
        purchaserId: this.$route.query.entityId,
        searchText: null,
        pageNum: 1,
        pageSize: 10,
      },
      formDetail: {
        preAcctId: null,
        pageNum: 1,
        pageSize: 10,
      },
      totalDetails: 0,
      statusObj: {
        '未支付': 'orange',
        '支付处理中': 'green',
        '已支付': 'purple',
        '支付失败': 'red',
        '待确认付款': 'green',
        '全部解冻': 'green',
        '提现处理中': 'green',
        '全部提现': 'green',
        '提现失败': 'red',
        '部分提现': 'green',
        '解冻处理中': 'green',
        '部分解冻': 'green',
        '解冻失败': 'red',
        '已关闭': '#d9d9d9',
        '已到账': 'purple'
      },
      detailList: [],
      previewVisible: false,
      bigImg: '',
      customData: null
    };
  },
  mounted() {
    this.preAcctList()
  },
  methods: {
    handleTableChange (e, str) {
      this[str].pageNum = e.current
      this[str].pageSize = e.pageSize
      switch (str) {
        case 'form': this.preAcctList();break;
        case 'formDetail': this.preAcctRecord(true);break;
      }
    },
    preAcctList() {
      this.loading = true
      preAcctList(this.form).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.data = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    view(preAcctId) {
      console.log(33)
      this.visibles = true
      this.formDetail.preAcctId = preAcctId
      this.preAcctRecord()
    },
    preAcctRecord (isPage) {
      this.loadingDetails = true
      // 重置数据
      if (isPage) {
        this.loadingDetails = true
      } else {
        this.spinDetails = true
        this.detailsObj = {}
        this.dataDetails = []
      }
      const arr = isPage ? [preAcctRecordList(this.formDetail)] : [preAcctRecordList(this.formDetail), preAcctRecord({id: this.formDetail.preAcctId})]
      Promise.all(arr).then(value => {
        this.spinDetails = false
        this.loadingDetails = false
        const res1 = value[0]
        if (res1.code === 200) {
          this.dataDetails = res1.data.list
          this.totalDetails = res1.data.total
        } else {
          this.$message.error(res1.message)
        }
        if (isPage) {
          return
        }
        const res2 = value[1]
        if (res2.code === 200) {
          this.detailsObj = res2.data
        } else {
          this.$message.error(res2.message)
        }
      }).catch(() => {
        this.spinDetails = false
        this.loadingDetails = false
      })
    },
    deductionFun(paymentNo) {
      this.dataDeduction = []
      this.loadingDeduction = true
      this.visibleDeduction = true
      useList({paymentNo}).then(res => {
        this.loadingDeduction = false
        this.dataDeduction = res.data
      })
    },
    //查看回单
    lookReceipt(img) {
      this.previewVisible = true
      this.bigImg = img
    },
    detailsCancel () {
      this.visibles = false
      this.formDetail.pageNum = 1
      this.formDetail.pageSize = 10
    },
    // 关闭支付
    closePay(id) {
      closePay(id).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.preAcctRecord()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    pay (e) {
      // 详情新增时判断刷新列表
      if (e) {
        this.preAcctRecord()
      } else {
        this.preAcctList()
      }
    },
    add () {
      if (this.detailsObj.supplierBank) {
        this.customData={id: this.detailsObj.supplierId, name: this.detailsObj.supplierName, bankNo: this.detailsObj.supplierBank.bankAccount}
      } else {
        this.customData={id: this.detailsObj.supplierId, name: this.detailsObj.supplierName,  bankNo: '*收款方未设置默认收款银行账号，请联系对方前往卖家中心-财务管理处理收款账号'}
      }
      this.$refs.payModal.visible = true
    }
  }
};
</script>

<style lang="less" scoped>
.AdvanceManagement {
  padding: 20px;
  width: 100%;
  background-color: #ffffff;
  border-left: none;
  .Advance-title {
    padding-bottom: 24px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 650;
  }
  .Advance-search {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
.tax-t {
  margin: 10px auto;
  font-size: 14px;
  font-weight: 400;
  color: rgba(228, 82, 0, 0.988235294117647);
}
.left-c {
  line-height: 32px;
  text-align: right;
}
.left-b {
  line-height: 10px;
  text-align: left;
}
.logo {
  width: 143px;
  height: 55px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  img {
    width: 100%;
  }
  .pt5 {
    padding: 5px 0 0 0;
  }
}
.zs {
  height: 250px;
}
.pt20 {
  padding: 0 0 0 20px;
}
// /deep/ .ant-col {
//   margin-bottom: 20px;
// }
/deep/ .ant-radio-wrapper {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}
/deep/ .ant-radio {
  &:nth-child(2) {
    width: 100%;
  }
}
.details {
  /deep/ .ant-modal-body {
    padding: 0;
  }
  color: #7f7f7f;
  .d-head {
    padding: 24px 28px;
    background-color: rgba(242, 242, 242, 1);
    /deep/ .ant-col-12 {
      line-height: 18px;
      &:nth-child(5) {
        margin-top: 12px;
      }
      &:nth-child(4) {
        margin-top: 12px;
      }
    }
    .d-w {
      font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
      font-weight: 700;
      font-style: normal;
      color: #333333;
    }
    .d-title {
      font-size: 18px;
      margin-bottom: 15px;
    }
    .d-text {
      font-size: 16px;
    }
    .d-text-o {
      font-size: 16px;
      color: #fd5d14;
    }
  }
  .d-table {
    padding: 20px;
  }
}
/deep/ .ant-table-column-title {
  font-family: 'Microsoft Tai Le Bold', 'Microsoft Tai Le', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
</style>