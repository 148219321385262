var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AdvanceManagement"},[_vm._m(0),_c('div',{staticClass:"Advance-search"},[_c('div',[_vm._v(" 搜索： "),_c('a-input-search',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入卖方名称"},on:{"search":function($event){_vm.form.pageNum = 1;_vm.preAcctList()}},model:{value:(_vm.form.searchText),callback:function ($$v) {_vm.$set(_vm.form, "searchText", $$v)},expression:"form.searchText"}})],1),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.$refs.payModal.visible = true}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 新增预付货款 ")],1)],1),_c('div',{staticClass:"tabls"},[_c('a-table',{attrs:{"rowKey":function (r, i) { return i; },"columns":_vm.columns,"data-source":_vm.data,"pagination":{
      size: 'big',
      total: _vm.total,
      current: _vm.form.pageNum,
      pageSize: _vm.form.pageSize,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['10', '20', '30', '40'],
      showTotal: function (total) { return ("共有 " + total + " 条记录"); }},"loading":_vm.loading},on:{"change":function($event){return _vm.handleTableChange($event, 'form')}},scopedSlots:_vm._u([{key:"handle",fn:function(text, row){return [_c('a',{on:{"click":function($event){return _vm.view(row.id)}}},[_vm._v("查看详情")])]}}])})],1),_c('AdvancePayModal',{ref:"payModal",attrs:{"purchaserId":_vm.$route.query.entityId,"customData":_vm.customData},on:{"ok":_vm.pay,"cancel":function($event){_vm.customData = null}}}),_c('a-modal',{staticClass:"details",attrs:{"zIndex":99,"width":990,"visible":_vm.visibles,"footer":""},on:{"cancel":_vm.detailsCancel}},[_c('a-spin',{attrs:{"spinning":_vm.spinDetails}},[_c('div',{staticClass:"d-head"},[_c('a-row',[_c('a-col',{staticClass:"d-w d-title",attrs:{"span":24}},[_vm._v("预付货款账户流水详情")]),_c('a-col',{attrs:{"span":12}},[_vm._v(" 卖方："),_c('span',{staticClass:"d-w d-text"},[_vm._v(_vm._s(_vm.detailsObj.supplierName))])]),_c('a-col',{attrs:{"span":12}},[_vm._v(" 买方："),_c('span',{staticClass:"d-w d-text"},[_vm._v(_vm._s(_vm.detailsObj.commercialName))])]),_c('a-col',{attrs:{"span":12}},[_vm._v(" 预付货款余额："),_c('span',{staticClass:"d-w d-text-o"},[_vm._v("¥ "+_vm._s(_vm.detailsObj.availableBalance))])]),_c('a-col',{attrs:{"span":12}},[_vm._v(" 未到账预付货款："),_c('span',{staticClass:"d-w d-text-o"},[_vm._v("¥ "+_vm._s(_vm.detailsObj.unreceivedBalance))])]),_c('a-col',{attrs:{"span":24,"align":"right"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 新增预付货款 ")],1)],1)],1)],1)]),_c('a-table',{staticClass:"d-table",attrs:{"dataSource":_vm.dataDetails,"columns":_vm.columnsDetails,"rowKey":function (r, i) { return i; },"size":"middle","pagination":{
      size: 'big',
      total: _vm.totalDetails,
      current: _vm.formDetail.pageNum,
      pageSize: _vm.formDetail.pageSize,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['10', '20', '30', '40'],
      showTotal: function (total) { return ("共有 " + total + " 条数据"); }},"loading":_vm.loadingDetails},on:{"change":function($event){return _vm.handleTableChange($event, 'formDetail')}},scopedSlots:_vm._u([{key:"payStatus",fn:function(text){return _c('span',{},[_c('a-badge',{attrs:{"color":_vm.statusObj[text],"text":text}})],1)}},{key:"handle",fn:function(text, record){return [(record.payStatus!='已关闭')?_c('a-space',[(record.payStatus=='未支付')?_c('a-popconfirm',{attrs:{"cancelText":"取消","okText":"确定","title":"确定关闭?"},on:{"confirm":function () { return _vm.closePay(record.paymentId); }}},[_c('a',[_vm._v("关闭支付")])]):_vm._e(),(record.bankReceiptFile)?_c('span',{on:{"click":function($event){return _vm.lookReceipt(record.bankReceiptFile)}}},[_c('a',[_vm._v("查看回单")])]):_vm._e(),(record.usedAmount != 0)?_c('a',{on:{"click":function($event){return _vm.deductionFun(record.paymentNo)}}},[_vm._v("抵扣详情")]):_vm._e()],1):_vm._e()]}}])})],1),(_vm.visibleDeduction)?_c('a-modal',{attrs:{"title":"抵扣详情","width":500,"visible":_vm.visibleDeduction,"footer":null,"centered":""},on:{"cancel":function($event){_vm.visibleDeduction = false}}},[_c('a-table',{staticClass:"d-table",attrs:{"rowKey":function (r, i) { return i; },"dataSource":_vm.dataDeduction,"loading":_vm.loadingDeduction,"columns":_vm.columnsDeduction,"size":"middle","pagination":false}})],1):_vm._e(),_c('a-modal',{attrs:{"width":800,"visible":_vm.previewVisible,"footer":null},on:{"cancel":function($event){_vm.previewVisible = false}}},[(_vm.bigImg.indexOf('.pdf') === -1)?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.bigImg}}):_c('iframe',{staticStyle:{"width":"100%","height":"800px","margin-top":"30px","border":"1px solid #f2f2f2"},attrs:{"src":_vm.bigImg,"frameborder":"0"}})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Advance-title"},[_c('p',[_vm._v("预付货款")])])}]

export { render, staticRenderFns }