<template>
  <a-modal title="预付货款" :zIndex="100" :visible="visible" footer="" :width="990" @cancel="cancel">
    <div class="payment">
      <a-spin :spinning="spining">
        <a-row class="pay-row">
          <a-col :span="3" class="col3" align='center'><span>预付收款方：</span> </a-col>
          <a-col :span="21">
            <a-auto-complete
            v-if="visible && !customData"
            style="width: 450px"
            placeholder="请输入公司关键字搜索"
            @focus="sellerList = [];"
            @select="completeSelect"
            @search="sellerList = [];keyword = $event"
            @keyup.enter.native="completeSearch()"
            :defaultActiveFirstOption="false"
            >
                <template slot="dataSource">
                  <template v-if="loadingOption">
                    <a-select-option disabled key="-1"><a-spin :spinning="true"></a-spin></a-select-option>
                  </template>
                  <template v-else>
                    <a-select-option v-for="(item, index) in sellerList" :key="index.toString()">{{item.supplierName}}</a-select-option>
                  </template>
                </template>
             <a-input>
                <a-button
                slot="suffix"
                style="margin-right: -12px"
                type="primary"
                @click="completeSearch"
                >
                <a-icon type="search" />
                </a-button>
            </a-input>
            </a-auto-complete>
            <span v-else>{{customData && customData.name}}</span>
            &nbsp;
          </a-col>

          <a-col :span="3" class="col3" align='center'><span>收款方账号：</span> </a-col>
          <a-col :span="21" v-if="customData" :class="!customData.bankNo && 'notice'">{{ customData.bankNo || '*收款方未设置默认收款银行账号，请联系对方前往卖家中心-财务管理处理收款账号' }}&nbsp;</a-col>
          <a-col :span="21" v-else>{{ supplierBankNo }}&nbsp;</a-col>
          

          <a-col :span="3" class="col3" align='center'><span>预付货款：</span> </a-col>
          <a-col :span="21">
            <a-space :size="30">
                <a-input-number v-model="formPost.payAmount" @blur="formPost.payAmount > limit && $message.error('输入金额超出预付货款上限')" style="width: 250px" placeholder="请输入预付货款金额" :precision="2" :min="0" />
                <span class="notice">*预付货款上限：{{limit}}</span>
            </a-space>
            
          </a-col>
        </a-row>

        <!-- v-if="this.authRoleId == 3||this.authRoleId==1" -->
        <a-row>
          <a-col :span="3" class="col3" align='center'>支付渠道：</a-col>
          <a-col :span="21">
            <a-radio-group v-model="radio" @change="onRadio">
              <a-radio class="l-flex-aligncenter" :style="radio == 1 ? radioStyle : ''" :value="1">
                <div class="l-flex-between">
                  <div class="logo">
                    <img :src="Logo" alt="" />
                  </div>
                  <div class="l-flex-between" style="width: 570px">
                    <p class="pt20">网关支付</p>
                  </div>
                </div>
              </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <!-- form格式提交支付 -->
        <form :action="form.reqUrl" method="post" v-show="false" target="_blank">
          <input type="text" :value="form.method" name="method" />
          <input type="text" :value="form.version" name="version" />
          <input type="text" :value="form.merId" name="merId" />
          <input type="text" :value="form.date" name="date" />
          <input type="text" :value="form.token" name="token" />
          <input type="text" :value="form.signMethod" name="signMethod" />
          <input type="text" :value="form.sign" name="sign" />
          <input type="submit" ref="sub" />
        </form>
        <!-- 选择支付方式 -->
        <!-- (this.authRoleId == 3||this.authRoleId == 1) -->
        <a-row class="zs" v-if="radio == 1">
          <a-col :span="6" class="left-c">选择支付账号</a-col>
          <a-col :span="18" class="pt20">
            <a-select v-model="formPost.bankNo" @change="handleChange" style="width: 510px">
              <a-select-option v-for="item in bankList" :key="item.bankAccount">
                {{ item.purchaserName+' '+item.bankAccount+' '+item.bankName  }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="6" class="left-c">支付账户类型</a-col>
          <a-col :span="18" class="pt20">
            <a-input value="企业" style="width: 510px" disabled></a-input>
          </a-col>
          <a-col :span="6" class="left-c">银行账号</a-col>
          <a-col :span="18" class="pt20">
            <a-input ref="fou" v-model="bankNo" class="bankNo" :maxLength="40" style="width: 510px"></a-input>
          </a-col>
        </a-row>

        <!--  v-if="this.authRoleId == 3 ||this.authRoleId==1" -->
        <a-row class="next">
          <a-col :sapn="24" align="center">
            <a-button class="sub-btn" type="primary" @click="sure" :loading="loading">
              确认支付
            </a-button>
          </a-col>
        </a-row>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { valid, info } from '@/api/pay'
import Logo from '@/assets/images/u154.png'
// import { getbanklist } from '@/api/bank'
import { payeeList, payeeLimit, payUrl } from '@/api/buyer'
export default {
  props: ['purchaserId', 'customData'],
  components: {
  },
  data() {
    return {
      visible: false,
      Logo: Logo,
      spining: false,
      radio: 1,
      radioStyle: {
        background: 'rgba(255, 204, 102, 1)'
      },
      msg: '',
      // 银行支付
      form: {
        method: '',
        version: '',
        merId: '',
        date: '',
        token: '',
        sign: '',
        reqUrl: '',
        signMethod: ''
      },

      loading: false,
      loadingOption: false,
      bankSelect: '',
      bankList: [],
      stat: false,
      sellerList: [], // 预付收款方卖家列表
      keyword: null, // 预付收款搜索关键字
      limit: null, // 可用最大预付金额
      supplierBankNo: '', // 收款方账号
      bankNo: '', // 自定义支付银行卡
      // 确认支付
      formPost: {
        supplierId: null,
        bankNo: null,
        bankName: null
      }
    }
  },
  watch: {
    customData(val) {
      console.log(val);
      if (val) {
        this.payeeLimit(val.id)
      }
    }
  },
  computed: {
    ...mapGetters(['getRoleP']),
  },
  created() {
    this.getbanklist()
  },
  methods: {
    getbanklist() {
    //   this.spining = true
      info({ purchaserId: this.purchaserId }).then((res) => {
        this.bankList = res.data.bankAccountList
        if (this.bankList[0]) {
          this.formPost.bankNo = this.bankList[0].bankAccount
          this.bankNo = this.bankList[0].bankAccount
          this.formPost.bankName = this.bankList[0].bankName
        }
      })
    },
    // 确认支付
    sure() {
      if (this.customData) {
        this.formPost.supplierId = this.customData.id
      }
      console.log(this.customData);
      if (!this.formPost.supplierId) {
        this.$message.error('请选择预付收款方')
        return
      }
      if (!this.formPost.payAmount) {
        this.$message.error('请输入预付货款金额')
        return
      }
      if (this.formPost.payAmount > this.limit) {
        this.$message.error('输入金额超出预付货款上限')
        return
      }
      if (!this.formPost.bankName) {
        this.$message.error('请选择支付账号')
        return
      }
      if (!this.bankNo) {
        this.$message.error('请输入付款账号')
        return
      }
      this.loading = true
      payUrl({...this.formPost, purchaserId: this.purchaserId, bankNo: this.bankNo}).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.form = res.data
          this.$nextTick(() => {
            this.$refs.sub.click()
            this.cancel()
          })
        } else {
          this.$message.error(res.message)
        }
        this.$emit('ok', !!this.customData)
      }).catch(() => {
        this.$emit('ok', !!this.customData)
        this.loading = false
      })
    },
    //选择银行卡
    handleChange(e) {
      const {bankName} = this.bankList.find((item) => {
        return item.bankAccount === e
      })
      this.bankNo = e
      this.formPost.bankName = bankName
      this.getVaild(bankName === '浙商银行')
    },
    // 验证银行卡是否是浙商的
    getVaild(e) {
      if (e) {
        this.stat = true
        return
      }
      valid({ cardNo: this.formPost.bankNo }).then(res => {
        if (res.code === 200) {
          this.stat = res.data.stat
        } else {
          this.stat = false
        }
      })
    },
    str(val) {
      if (!val) return
      const suffix = val.substring(val.length - 4)
      const prefix = val.substring(0, 5)
      console.log(val, prefix + '******' + suffix)
      return prefix + '*********' + suffix
    },
    removeDuplicate (arr) {
      let len = arr.length
      for (let i = 0; i < len; i++) {
          for (let j = i + 1; j < len; j++) {
            if (arr[i].id === arr[j].id) {
            arr.splice(j, 1)
            len--
            j--
          }
        }
      }
      return arr
    },
    completeSelect (e) {
        console.log(222, e);
        const {id, bankNo, supplierName} = this.sellerList[e]
        this.keyword = supplierName
        this.formPost.supplierId = id
        this.supplierBankNo = bankNo
        this.payeeLimit(id)
    },
    payeeLimit(supplierId) {
      payeeLimit({ supplierId, purchaserId: this.purchaserId }).then(res => {
        if (res.code === 200) {
          this.limit = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    completeSearch () {
        console.log(this.keyword);
        if (this.keyword) {
            this.form.supplierId = null
            this.form.bankNo = null
            this.loadingOption = true
            payeeList({ searchKey: this.keyword, purchaserId: this.purchaserId }).then(res => {
              this.loadingOption = false
                if (res.code === 200) {
                  this.sellerList = this.removeDuplicate(res.data)
                } else {
                  this.$message.error(res.message)
                }
                console.log(22222222222222222222,this.sellerList)
            }).catch(() => {
              this.loadingOption = false
            })
        } else {
            this.sellerList = []
        }
    },
    cancel () {
        this.$emit('cancel')
        this.visible = false
        this.formPost = {
            supplierId: null,
            bankNo: null,
            bankName: null
        }
        if (this.bankList[0]) {
          this.bankNo = this.bankList[0].bankAccount
          this.formPost.bankNo = this.bankList[0].bankAccount
          this.formPost.bankName = this.bankList[0].bankName
        }
        this.supplierBankNo = ''
        this.sellerList = []
        this.limit = null
        this.keyword = null
        this.form = {
          method: '',
          version: '',
          merId: '',
          date: '',
          token: '',
          sign: '',
          reqUrl: '',
          signMethod: ''
        }
    }
  }
}
</script>

<style lang="less" scoped>
.left-c {
  line-height: 32px;
  text-align: right;
}
.left-b {
  line-height: 10px;
  text-align: left;
}
.logo {
  width: 143px;
  height: 55px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  img {
    margin-top: 5px;
    width: 100%;
  }
  .pt5 {
    padding: 5px 0 0 0;
  }
}
.zs {
  height: 210px;
}
.pt20 {
  padding: 0 0 0 20px;
}
/deep/ .ant-col {
  margin-bottom: 20px;
}
/deep/ .ant-radio-wrapper {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}
/deep/ .ant-radio {
  &:nth-child(2) {
    width: 100%;
  }
}
// /deep/.ant-input-number:hover {
//   border-color: red;
// }
// /deep/.ant-input-number-focused {
//   border-color: red;
//   box-shadow: none;
// }
// /deep/.bankNo {
//   box-shadow: none;
//   border-color: none;
//   &:hover {
//     border-color: red;
//   }
//   &:focus {
//     border-color: red;
//   }
// }
.notice {
  color: #333;
  font-size: 14px;
}
.red {
  color: red;
}
.col3 {
  font-size: 16px;
  color: #333;
  padding-right: 6px;
  text-align: right;
}
.fl {
  font-size: 22px;
  color: #f5222d;
  line-height: 1;
}
.fb-conter {
  margin-left: 38px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}

.pay-row {
    /deep/ .ant-col {
        line-height: 32px;
    }
}
.sub-btn {
    width: 112px;
    height: 38px;
    border-radius: 4px;
    font-size: 16px;
}
.notice {
    color: @error;
}
</style>